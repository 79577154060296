import React, { useState, useContext } from "react";
import Modal from "react-modal";
import { HeaderContext } from "../HeaderContext";

// Ensure that the modal's root element is attached to your app's root element
Modal.setAppElement("#root");

const DegreeCard = ({ diplomaImage, translatedText, trailer }) => {
  const [isHoverEnabled, setIsHoverEnabled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setIsHeaderVisible } = useContext(HeaderContext);

  const toggleHover = () => {
    setIsHoverEnabled(!isHoverEnabled);
  };

  const openModal = () => {
    setIsModalOpen(true);
    setIsHeaderVisible(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsHeaderVisible(true);
  };

  return (
    <div className="flex justify-start items-stretch">
      <div className="relative " onClick={openModal}>
        <img
          src={diplomaImage}
          alt="Diploma"
          className="object-fit w-full h-full cursor-pointer "
        />
        {isHoverEnabled && (
          <div className=" border border-gray-200 shadow-smr absolute inset-0 flex items-center justify-center bg-black bg-opacity-75 opacity-0 hover:opacity-100 transition-opacity duration-300">
            <p className="text-white text-center px-4">{trailer}</p>
          </div>
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Diploma Image"
        className="flex items-center justify-center min-h-screen bg-black bg-opacity-75"
        overlayClassName="fixed inset-0 bg-black bg-opacity-75"
      >
        <div className="relative max-w-full max-h-full p-4">
          <img
            src={diplomaImage}
            alt="Diploma"
            className="w-full h-auto max-w-full max-h-screen"
          />
          {isHoverEnabled && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-75 opacity-0 hover:opacity-100 transition-opacity duration-300">
              <p className="text-white text-center px-4  text-4xl">
                {translatedText}
              </p>
            </div>
          )}
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 text-white text-5xl"
          >
            &times;
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default DegreeCard;
