import React from "react";
import profileImg from "../../assets/pic.jpg";

function ProfileImage() {
  return (
    <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full ">
      <div className="flex flex-col grow  justify-center max-md:max-w-full h-[500px] md:h-full">
        <img
          loading="lazy"
          src={profileImg}
          alt="Oxana Kravchenko"
          className="h-full w-full scale-100 object-cover max-md:max-w-full"
        />
      </div>
    </div>
  );
}

export default ProfileImage;
