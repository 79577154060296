import { useTranslation } from "react-i18next";
import React from "react";
import CardInfo from "./CardInfo";
const Card = () => {
  const [t, i18n] = useTranslation("global");

  let services = [
    {
      title: t("services.plans.Individual Consultations"),
      price: "250 AED",
      description: t(
        "services.plans.A personalized consultation to address your unique concerns and goals."
      ),
      link: "https://calendly.com/contact-almaz-consult/60min?month=2024-11&date=2024-11-06",
    },
    {
      title: t("services.plans.Leadership Coaching"),
      price: "250 AED",
      description: t(
        "services.plans.Coaching to enhance your leadership skills and achieve professional success."
      ),
      link: "https://calendly.com/contact-almaz-consult/60min?month=2024-11&date=2024-11-06",
    },
    {
      title: t("services.plans.Work with a Team & Organizational Consulting"),
      price: t("services.plans.Per Request"),
      description: t(
        "services.plans.Tailored consulting services for teams and organizations to improve dynamics and performance."
      ),
      link: "https://calendly.com/contact-almaz-consult/60min?month=2024-11&date=2024-11-06",
    },
  ];
  return (
    <section className=" bg-white  flex items-center justify-center">
      <div className="max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:items-stretch md:grid-cols-3 md:gap-8">
          {services.map((plan) => (
            <CardInfo service={plan} />
          ))}
        </div>
        <div className="mt-6 bg-gray-50 p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            {t("services.plans.Additional Information")}
          </h3>
          <ul className="space-y-4 text-gray-700">
            <li className="flex items-start">
              <svg
                className="h-6 w-6 text-green-600 flex-shrink-0 mr-3"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3a1 1 0 001 1h2a1 1 0 100-2h-1V7zm-1 8a1 1 0 110-2 1 1 0 010 2z"
                  clipRule="evenodd"
                />
              </svg>
              {t("services.plans.A consultation is 55 minutes")}
            </li>
            <li className="flex items-start">
              <svg
                className="h-6 w-6 text-green-600 flex-shrink-0 mr-3"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M2 5a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm4 4a2 2 0 110-4 2 2 0 010 4zm0 4a2 2 0 110-4 2 2 0 010 4zm5-8h1v8h-1V5zm2 0h1v8h-1V5z"
                  clipRule="evenodd"
                />
              </svg>
              {t("services.plans.Can be done in English or Russian")}
            </li>
            <li className="flex items-start">
              <svg
                className="h-6 w-6 text-green-600 flex-shrink-0 mr-3"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M2 3a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V3zm13.5 7.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM8 12a1 1 0 110-2h4a1 1 0 110 2H8zm5-4a1 1 0 110-2 1 1 0 010 2z"
                  clipRule="evenodd"
                />
              </svg>
              {t(
                "services.plans.You will receive a link to your indicated email"
              )}
            </li>
            <li className="flex items-start">
              <svg
                className="h-6 w-6 text-green-600 flex-shrink-0 mr-3"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M6.707 4.293a1 1 0 00-1.414 0l-4 4a1 1 0 001.414 1.414L5 6.414V15a1 1 0 102 0V6.414l2.293 2.293a1 1 0 101.414-1.414l-4-4z"
                  clipRule="evenodd"
                />
              </svg>
              {t(
                "services.plans.Change / Cancellation minimum 24 hours in advance"
              )}
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Card;
