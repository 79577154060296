import React, { useState } from "react";
import InputField from "./InputField";
import TextArea from "./TextArea";
import CheckboxField from "./CheckboxField";
import Button from "./Button";
import Socials from "./Socials";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";

function ContactForm() {
  const { t } = useTranslation("global");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    agreeToPolicy: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_x15uj16", // Replace with your EmailJS service ID
        "template_nt556bq", // Replace with your EmailJS template ID
        formData,
        "bwMacAb15JvzSObhi" // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          alert("Message sent successfully!");
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            message: "",
            agreeToPolicy: false,
          });
        },
        (error) => {
          alert("Failed to send the message. Please try again.");
          console.error("Failed to send message:", error);
        }
      );
  };

  const inputFields = [
    {
      label: t("contact.First name"),
      placeholder: "First name",
      name: "firstName",
    },
    {
      label: t("contact.Last name"),
      placeholder: "Last name",
      name: "lastName",
    },
    {
      label: t("contact.Email"),
      placeholder: "you@company.com",
      type: "email",
      name: "email",
    },
  ];

  return (
    <form
      className="flex flex-col w-full max-w-[480px]"
      onSubmit={handleSubmit}
    >
      <h2 className="text-4xl font-semibold tracking-tighter leading-none text-stone-700 max-md:max-w-full">
        {t("contact.title")}
      </h2>
      <div className="flex flex-col mt-2 w-full max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 items-start w-full max-md:max-w-full">
          {inputFields.slice(0, 2).map((field, index) => (
            <InputField
              key={index}
              {...field}
              value={formData[field.name]}
              onChange={handleChange}
            />
          ))}
        </div>

        {inputFields.slice(2).map((field, index) => (
          <InputField
            key={index + 2}
            {...field}
            value={formData[field.name]}
            onChange={handleChange}
          />
        ))}

        <TextArea
          label={t("contact.Message")}
          name="message"
          value={formData.message}
          onChange={handleChange}
        />

        <CheckboxField
          label={
            <>
              {t("contact.You agree to our friendly")}{" "}
              <a href="#" className="underline">
                privacy policy
              </a>
              .
            </>
          }
          name="agreeToPolicy"
          checked={formData.agreeToPolicy}
          onChange={handleChange}
        />

        <Button text={t("contact.Send message")} />
        <Socials />
      </div>
    </form>
  );
}

export default ContactForm;
