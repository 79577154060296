import React from "react";
import DegreeCard from "../components/degrees/DegreeCard";
import Diplome1 from "../assets/diplomas/diplome1.jpg";
import Diplome2 from "../assets/diplomas/diplome2.png";
import Diplome3 from "../assets/diplomas/diplome3.jpg";
import Diplome4 from "../assets/diplomas/diplome4.png";
import Diplome5 from "../assets/diplomas/diplome5.jpg"; // Import the new diploma image
import { useTranslation } from "react-i18next";

const DegreesPage = () => {
  const [t, i18n] = useTranslation("global");

  const diplomas = [
    {
      id: 1,
      image: Diplome1,
      trailer: "Master Degree in Psychology, cum laude ",
      translation: "Master Degree in Psychology, cum laude ",
    },
    {
      id: 2,
      image: Diplome2,
      trailer: "Membership in Russian Cognitive Behavioral Association ",
      translation: "Membership in Russian Cognitive Behavioral Association ",
    },
    {
      id: 3,
      image: Diplome3,
      trailer: "Certificate of continuing education",
      translation: "Certificate of continuing education",
    },
    {
      id: 4,
      image: Diplome4,
      trailer: "",
      translation: "",
    },
    {
      id: 5,
      image: Diplome5,
      trailer: "Certificate of continuing education ",
      translation: "Certificate of continuing education",
    },
  ];

  return (
    <div className="container mx-auto mt-20">
      <div className="max-w-2xl mx-auto text-center py-10">
        <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl text-zinc-700">
          {t("degrees.title")}
        </h2>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-1 sm:grid-rows-3 md:grid-cols-2 md:grid-rows-2 lg:grid-cols-3 gap-4 mb-7 ">
        {/* First Row: Two Images */}
        {diplomas.slice(0, 2).map((diploma) => (
          <div className="h-fit p-1 md:p-0">
            <DegreeCard
              key={diploma.id}
              diplomaImage={diploma.image}
              translatedText={diploma.translation}
              trailer={diploma.trailer}
            />
          </div>
        ))}

        {/* Second Row: One Image */}
        <div className="md:col-span-2 lg:col-span-1 flex justify-start items-center row-span-2 col-span-2">
          <DegreeCard
            key={diplomas[3].id}
            diplomaImage={diplomas[3].image}
            translatedText={diplomas[3].translation}
            trailer={diplomas[3].trailer}
          />
        </div>

        {/* Third Row: Two Images */}
        <div className="h-fit p-1 md:p-0">
          <DegreeCard
            key={diplomas[2].id}
            diplomaImage={diplomas[2].image}
            translatedText={diplomas[2].translation}
            trailer={diplomas[2].trailer}
          />
        </div>
        <div className="h-fit p-1 md:p-0">
          <DegreeCard
            key={diplomas[4].id}
            diplomaImage={diplomas[4].image}
            translatedText={diplomas[4].translation}
            trailer={diplomas[4].trailer}
          />
        </div>
      </div>
    </div>
  );
};

export default DegreesPage;
