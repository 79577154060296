import { useTranslation } from "react-i18next";
import React, { useContext, useState } from "react";
import logo from "../assets/Logo5.png";
import { HeaderContext } from "./HeaderContext";
import LanguageSwitcher from "./LanguageSwitcher";
import { FaBars, FaTimes } from "react-icons/fa";
import { BsChevronDown } from "react-icons/bs";
const Header = () => {
  const [t, i18n] = useTranslation("global");
  const { isHeaderVisible } = useContext(HeaderContext);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    isHeaderVisible && (
      <header className="fixed inset-x-0 top-0 z-30 mx-auto w-full max-w-screen-md border border-gray-100 bg-white md:bg-white/20 py-3 shadow backdrop-blur-lg md:top-6 md:rounded-3xl lg:max-w-screen-lg  ">
        <div className="px-4">
          <div className="flex items-center justify-between">
            {/* Logo */}
            <div className="flex shrink-0">
              <a aria-current="page" className="flex items-center" href="/">
                <img
                  className="transform scale-100 pt-1 md:scale-150 lg:scale-120 md:p-1  lg:p-1 w-[50px] h-[50px]"
                  src={logo}
                  alt="Logo"
                />
              </a>
            </div>

            {/* Hamburger Icon for Mobile */}
            <div className="md:hidden">
              <button
                onClick={toggleMenu}
                className="text-gray-900 focus:outline-none pt-2"
              >
                {menuOpen ? <FaTimes size={24} /> : <BsChevronDown size={24} />}
              </button>
            </div>

            <div
              className={`${
                menuOpen ? "block" : "hidden"
              } md:flex md:items-center md:justify-center md:gap-5 absolute md:static top-full left-0 w-full md:w-auto bg-white md:bg-transparent shadow-md md:shadow-none p-4 md:p-0`}
            >
              <div className="flex flex-col md:flex-row md:gap-5 md:justify-center md:items-center text-center">
                <a
                  className="block md:inline-block rounded-lg px-2 py-1 text-sm font-medium text-gray-900 transition-all duration-200 hover:bg-gray-100 hover:text-green-900"
                  href="/degrees"
                >
                  {t("navbar.degrees")}
                </a>
                <a
                  aria-current="page"
                  className="block md:inline-block rounded-lg px-2 py-1 text-sm font-medium text-gray-900 transition-all duration-200 hover:bg-gray-100 hover:text-green-900"
                  href="/services"
                >
                  {t("navbar.services")}
                </a>
                <a
                  aria-current="page"
                  className="block md:inline-block rounded-lg px-2 py-1 text-sm font-medium text-gray-900 transition-all duration-200 hover:bg-gray-100 hover:text-green-900"
                  href="/areas-of-expertise"
                >
                  {t("navbar.areas")}
                </a>
                <a
                  aria-current="page"
                  className={`${
                    menuOpen ? "block" : "hidden"
                  }  rounded-lg px-2 py-1 text-sm font-medium text-gray-900 transition-all duration-200 hover:bg-gray-100 hover:text-green-900`}
                  href="/contact&book"
                >
                  {t("navbar.contact")}
                </a>
              </div>
            </div>

            {/* Contact Button and Language Switcher */}
            <div className="md:flex items-center justify-end gap-3 ">
              <a
                className=" hidden md:inline-flex items-center justify-center rounded-xl bg-[#0b3922] px-3 py-2 text-sm font-semibold text-white shadow-sm transition-all duration-150 hover:bg-[#0f4c2d] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                href="/contact&book"
              >
                {t("navbar.contact")}
              </a>
              <LanguageSwitcher />
            </div>
          </div>
        </div>
      </header>
    )
  );
};

export default Header;
{
  /* Navigation Links */
}
