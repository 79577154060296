import React from "react";
import { useTranslation } from "react-i18next";
// bg-white shadow-lg rounded-lg
const AreasOfExpertise = () => {
  const { t } = useTranslation("global");
  return (
    <section className="min-h-screen flex items-center justify-center p-8">
      <div className="max-w-5xl w-full text-center  p-8 mt-16">
        <h2 className="text-3xl font-bold text-gray-800 mb-6">
          {t("areas of expertise.title")}
        </h2>
        <p className="text-lg text-gray-600 mb-6">
          {t("areas of expertise.description")}
        </p>
        <ul className="text-left space-y-4">
          {[
            t(
              "areas of expertise.Career navigation, including planning and transitions"
            ),
            t(
              "areas of expertise.Harnessing personal resources for growth and success"
            ),
            t(
              "areas of expertise.Addressing challenges related to immigration, relocation, and adjustment"
            ),
            t(
              "areas of expertise.Cultivating life balance amidst various demands and responsibilities"
            ),
            t(
              "areas of expertise.Improving communication dynamics with others"
            ),
            t(
              "areas of expertise.Embarking on a journey of self-discovery and enhancing self-esteem"
            ),
            t(
              "areas of expertise.Managing stress, preventing burnout, and fostering resilience"
            ),
            t(
              "areas of expertise.Developing skills for emotional regulation and well-being"
            ),
            t(
              "areas of expertise.Exploring existential questions and searching for deeper meaning in life"
            ),
          ].map((item, index) => (
            <li key={index} className="flex items-start">
              <svg
                className="h-6 w-6 text-[#1e985a] mt-1 mr-3"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <span className="text-gray-700 text-lg">{item}</span>
            </li>
          ))}
        </ul>
        <p className="text-lg text-gray-600 mt-6">
          {t("areas of expertise.p")}
        </p>
      </div>
    </section>
  );
};

export default AreasOfExpertise;
