import React from "react";

function Socials() {
  const openInNewWindow = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <div className="flex h-auto w-full flex-wrap items-center justify-center gap-5 bg-white py-8">
      <a
        className="flex h-12 w-12 cursor-pointer items-center justify-center rounded-md bg-gray-100 shadow-md shadow-transparent transition-all duration-300 hover:shadow-blue-200"
        href="mailto:contact@almaz-consult.com"
      >
        <img
          className="w-[38px] h-[38px]"
          src="https://img.icons8.com/?size=100&id=ut6gQeo5pNqf&format=png&color=000000"
        />
      </a>
      <a
        className="flex h-12 w-12 cursor-pointer items-center justify-center rounded-md bg-gray-100 shadow-md shadow-transparent transition-all duration-300 hover:shadow-indigo-200"
        href="https://wa.me/message/ILGYJUX473TXP1"
      >
        <svg
          className="cursor-pointer rounded-md shadow-md shadow-transparent transition-all duration-300 hover:shadow-green-200"
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 93 92"
          fill="none"
        >
          <rect
            x="1.13867"
            width="91.5618"
            height="91.5618"
            rx="15"
            fill="#ECFFF5"
          />
          <path
            d="M23.5762 66.8405L26.8608 54.6381C24.2118 49.8847 23.3702 44.3378 24.4904 39.0154C25.6106 33.693 28.6176 28.952 32.9594 25.6624C37.3012 22.3729 42.6867 20.7554 48.1276 21.1068C53.5685 21.4582 58.6999 23.755 62.5802 27.5756C66.4604 31.3962 68.8292 36.4844 69.2519 41.9065C69.6746 47.3286 68.1228 52.7208 64.8813 57.0938C61.6399 61.4668 56.9261 64.5271 51.605 65.7133C46.284 66.8994 40.7125 66.1318 35.9131 63.5513L23.5762 66.8405ZM36.508 58.985L37.2709 59.4365C40.7473 61.4918 44.8076 62.3423 48.8191 61.8555C52.8306 61.3687 56.5681 59.5719 59.4489 56.7452C62.3298 53.9185 64.1923 50.2206 64.7463 46.2279C65.3002 42.2351 64.5143 38.1717 62.5113 34.6709C60.5082 31.1701 57.4003 28.4285 53.6721 26.8734C49.9438 25.3184 45.8045 25.0372 41.8993 26.0736C37.994 27.11 34.5422 29.4059 32.0817 32.6035C29.6212 35.801 28.2903 39.7206 28.2963 43.7514C28.293 47.0937 29.2197 50.3712 30.9732 53.2192L31.4516 54.0061L29.6153 60.8167L36.508 58.985Z"
            fill="#00D95F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.0259 46.8847C54.5787 46.5249 54.0549 46.2716 53.4947 46.1442C52.9344 46.0168 52.3524 46.0186 51.793 46.1495C50.9524 46.4977 50.4093 47.8134 49.8661 48.4713C49.7516 48.629 49.5833 48.7396 49.3928 48.7823C49.2024 48.8251 49.0028 48.797 48.8316 48.7034C45.7543 47.5012 43.1748 45.2965 41.5122 42.4475C41.3704 42.2697 41.3033 42.044 41.325 41.8178C41.3467 41.5916 41.4555 41.3827 41.6286 41.235C42.2344 40.6368 42.6791 39.8959 42.9218 39.0809C42.9756 38.1818 42.7691 37.2863 42.3269 36.5011C41.985 35.4002 41.3344 34.42 40.4518 33.6762C39.9966 33.472 39.4919 33.4036 38.9985 33.4791C38.5052 33.5546 38.0443 33.7709 37.6715 34.1019C37.0242 34.6589 36.5104 35.3537 36.168 36.135C35.8256 36.9163 35.6632 37.7643 35.6929 38.6165C35.6949 39.0951 35.7557 39.5716 35.8739 40.0354C36.1742 41.1497 36.636 42.2144 37.2447 43.1956C37.6839 43.9473 38.163 44.6749 38.6801 45.3755C40.3607 47.6767 42.4732 49.6305 44.9003 51.1284C46.1183 51.8897 47.42 52.5086 48.7799 52.973C50.1924 53.6117 51.752 53.8568 53.2931 53.6824C54.1711 53.5499 55.003 53.2041 55.7156 52.6755C56.4281 52.1469 56.9995 51.4518 57.3795 50.6512C57.6028 50.1675 57.6705 49.6269 57.5735 49.1033C57.3407 48.0327 55.9053 47.4007 55.0259 46.8847Z"
            fill="#00D95F"
          />
        </svg>
      </a>{" "}
      <a
        className="cursor-pointer rounded-md bg-blue-50 shadow-md shadow-transparent transition-all duration-300 hover:shadow-indigo-200"
        href="https://t.me/oxana_krv"
      >
        <svg
          className="cursor-pointer rounded-md shadow-md shadow-transparent transition-all duration-300 hover:shadow-sky-200"
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 92 93"
          fill="none"
        >
          <rect
            x="0.138672"
            y="1"
            width="91.5618"
            height="91.5618"
            rx="15"
            fill="#E5F7FF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M71.153 49.5768C71.153 63.3922 59.9534 74.5918 46.138 74.5918C32.3227 74.5918 21.123 63.3922 21.123 49.5768C21.123 35.7614 32.3227 24.5618 46.138 24.5618C59.9534 24.5618 71.153 35.7614 71.153 49.5768ZM48.5352 41.901C44.2377 43.7573 31.8466 49.0289 31.8466 49.0289C28.91 50.2169 30.6289 51.3306 30.6289 51.3306C30.6289 51.3306 33.1357 52.2216 35.2846 52.8898C37.4333 53.558 38.5793 52.8156 38.5793 52.8156C38.5793 52.8156 43.5931 49.4002 48.6784 45.7619C52.2597 43.2375 51.4002 45.3165 50.5407 46.2075C48.6784 48.138 45.5986 51.1821 43.02 53.6323C41.8741 54.6719 42.4471 55.5628 42.9485 56.0083C44.4067 57.2873 47.8411 59.6135 49.4003 60.6696C49.8324 60.9623 50.1204 61.1574 50.1826 61.2057C50.5407 61.5028 52.5463 62.8392 53.7639 62.5422C54.9815 62.2452 55.1247 60.5374 55.1247 60.5374C55.1247 60.5374 56.0558 54.4491 56.9154 48.8804C57.0746 47.787 57.2338 46.7166 57.3822 45.7184C57.768 43.1241 58.0812 41.0182 58.133 40.2675C58.3479 37.7431 55.7693 38.7825 55.7693 38.7825C55.7693 38.7825 50.1826 41.1586 48.5352 41.901Z"
            fill="#34AADF"
          />
        </svg>
      </a>
    </div>
  );
}

export default Socials;
