import React from "react";

function InputField({
  label,
  placeholder,
  type = "text",
  name,
  value,
  onChange,
}) {
  return (
    <div className="flex flex-col flex-1 shrink basis-0 mt-6">
      <label className="text-sm font-medium leading-none text-slate-700">
        {label}
      </label>

      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="flex overflow-hidden gap-2 items-center px-4 py-3 mt-1.5 w-full text-base text-gray-500 bg-white rounded-lg shadow-sm border border-gray-300"
        aria-label={label}
      />
    </div>
  );
}

export default InputField;
