import React from "react";
import { useTranslation } from "react-i18next";

export default function CardInfo({ service }) {
  const { t } = useTranslation("global");
  return (
    <div className="flex flex-col justify-between h-full divide-y divide-gray-200 rounded-2xl border border-gray-200 shadow-sm">
      <div className="p-6 sm:px-8">
        <h2 className="text-lg font-medium text-gray-900">
          {service.title}
          <span className="sr-only">Session</span>
        </h2>

        <p className="mt-2 text-gray-700">{service.description}</p>

        <p className="mt-2 sm:mt-4">
          <strong className="text-3xl font-bold text-gray-900 sm:text-4xl">
            {" "}
            {service.price}{" "}
          </strong>
        </p>
      </div>
      <div className="p-6 sm:px-8">
        <a
          className="block rounded border border-green-900 bg-[#25504e] px-12 py-3 text-center text-sm font-medium text-white hover:bg-transparent hover:text-green-900 focus:outline-none focus:ring active:text-amber-500"
          href={service.link}
        >
          {t("services.bookbtn")}
        </a>
      </div>
    </div>
  );
}
