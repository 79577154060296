import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaGlobe } from "react-icons/fa";

function LanguageSwitcher() {
  const { i18n } = useTranslation("global");
  const [language, setLanguage] = useState(
    localStorage.getItem("i18nextLng") || "en"
  );

  const toggleLanguage = () => {
    const newLang = language === "ru" ? "en" : "ru";
    i18n.changeLanguage(newLang);
    localStorage.setItem("i18nextLng", newLang);
    setLanguage(newLang); // Update the state to trigger re-render
  };

  useEffect(() => {
    // Ensure the initial state is synced with i18n language if changed elsewhere
    setLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <div className="flex items-center cursor-pointer" onClick={toggleLanguage}>
      <FaGlobe className="text-xl text-gray-900 hover:text-green-900" />
      <span className="ml-1 text-sm font-medium text-gray-900">
        {language === "en" ? "RU" : "EN"}
      </span>
    </div>
  );
}

export default LanguageSwitcher;
