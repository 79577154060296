import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { initReactI18next } from "react-i18next";
import global_ru from "./locales/ru/translation.json";
import global_en from "./locales/en/translation.json";

i18next
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    debug: true,
    interpolation: { escapeValue: false },
    lng: localStorage.getItem("i18nextLng") || "en", // Check localStorage first, default to "en"
    resources: {
      en: {
        global: global_en,
      },
      ru: {
        global: global_ru,
      },
    },
  });

console.log(localStorage.getItem("i18nextLng"));
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);
