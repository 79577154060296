import React from "react";

function Button({ text }) {
  return (
    <button className="flex items-start justify-center w-full px-5 py-3 mt-8 text-base font-medium text-white rounded-lg shadow-sm bg-[#25504e] max-md:max-w-full">
      {text}
    </button>
  );
}

export default Button;
