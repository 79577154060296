import React from "react";
import Card from "../components/servicep/Card";
import { useTranslation } from "react-i18next";

const Services = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <div className="max-w-2xl mx-auto text-center mt-28">
        <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl text-zinc-700">
          {t("services.title")}
        </h2>
      </div>
      <Card />;
    </div>
  );
};

export default Services;
