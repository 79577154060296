import React from "react";

function TextArea({ label, name, value, onChange }) {
  return (
    <div className="flex flex-col mt-6 w-full text-sm font-medium leading-none whitespace-nowrap min-h-[154px] text-slate-700 max-md:max-w-full">
      <label htmlFor="message">{label}</label>

      <textarea
        name={name}
        value={value}
        onChange={onChange}
        id="message"
        className="flex flex-1 gap-2 py-2.5 mt-1.5 w-full bg-white rounded-lg shadow-sm min-h-[128px] max-md:max-w-full border border-gray-300"
        aria-label={label}
      />
    </div>
  );
}

export default TextArea;
