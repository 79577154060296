import React from "react";
import ContactForm from "../components/contact/ContactForm";
import ContactImg from "../assets/contactImg.png";

const Contact = () => {
  return (
    <main className="flex flex-col justify-center items-center py-20 bg-white min-h-[960px]">
      <div className="flex flex-wrap flex-1 gap-5 justify-center px-8 max-w-full h-full rounded-sm w-[1280px] max-md:px-5 ">
        <div className="flex flex-col flex-1 shrink justify-center items-center basis-0 min-w-[240px] max-md:max-w-full ">
          <ContactForm />
        </div>

        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/638213b3cd6dce5f0ca8ef1589d056e173da6c5fd749d2dc059ac5bcf91c1e18?apiKey=b8f56206a4064d949ab07c4d2a67b471&&apiKey=b8f56206a4064d949ab07c4d2a67b471"
          alt="Contact illustration"
          className="object-contain flex-1 shrink w-full aspect-[0.72] basis-0 min-w-[240px] rounded-[30px] max-md:max-w-full py-10"
        />
      </div>
    </main>
  );
};

export default Contact;
