import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./Pages/Home";
import Services from "./Pages/Services";
import AreasOfExpertise from "./Pages/AreasOfExpertise";
import Degrees from "./Pages/Degrees";
import Contact from "./Pages/Contact";
import Footer from "./components/Footer";
import { HeaderProvider } from "./components/HeaderContext";
import "./index.css";
import ErrorPage from "./Pages/ErrorPage";

const App = () => {
  return (
    <HeaderProvider>
      <Router>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/areas-of-expertise" element={<AreasOfExpertise />} />
            <Route path="/degrees" element={<Degrees />} />
            <Route path="/contact&book" element={<Contact />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          {/* <Footer /> */}
        </div>
      </Router>
    </HeaderProvider>
  );
};

export default App;
