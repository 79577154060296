import React from "react";
import ProfileImage from "../components/home/ProfileImage";
import ProfileInfo from "../components/home/ProfileInfo";
const Home = () => {
  return (
    <section className="flex flex-col justify-center w-full  max-md:max-w-full ">
      <div className="w-full max-md:max-w-full ">
        <div className="flex gap-5 max-md:flex-col h-screen">
          <ProfileImage />

          <ProfileInfo />
        </div>
      </div>
    </section>
  );
};

export default Home;
