import React from "react";
import { useTranslation } from "react-i18next";

function ProfileInfo() {
  const { t } = useTranslation("global");
  return (
    <div className=" flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow justify-center max-md:max-w-full">
        <article className="flex flex-col  bg-white max-md:max-w-full h-fit">
          <h1 className="px-16 pt-40 md:pb-5 text-8xl whitespace-nowrap leading-[65px] text-[#0b3922] w-[492px] max-md:pt-10 max-md:pl-5 max-md:max-w-full md:text-8xl md:leading-[100px] text-[62px] ">
            Oxana <br />
            Kravchenko
          </h1>

          <div className="flex max-md:flex-wrap max-md:max-w-full">
            <div className="flex flex-col shrink-0 items-end self-start pl-6 pr-4 md:pl-16 md:pr-4 pt-3.5 pb-2.5 basis-0 grow-0 w-fit">
              <div className="shrink-0 border-t-2 border-[#0b3922] h-[5px] md:w-[150px] w-[50px]" />
            </div>

            <h2 className="grow  pb-10 md:pb-16 md:text-xl text-[#0b3922] w-fit max-md:max-w-full">
              {t("home.work")}
            </h2>
          </div>

          <p className="px-16 pb-2.5 mt-1.5 mb-16 text-sm leading-7 text-gray-500 w-[592px] max-md:pl-5 max-md:mb-10 max-md:max-w-full">
            <b className="text-xl">
              <q className="text-[#0f4c2d] ">{t("home.quote")}</q>– C.S. Lewis
            </b>
            <br />
            {t("home.p1")}
            <br />
            {t("home.p2")}

            <br />
            {t("home.p3")}
          </p>
        </article>
      </div>
    </div>
  );
}

export default ProfileInfo;
