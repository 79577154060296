import React from "react";

function CheckboxField({ label, name, checked, onChange }) {
  return (
    <div className="flex gap-3 items-center mt-6 w-full max-md:max-w-full">
      <input
        name={name}
        checked={checked}
        onChange={onChange}
        type="checkbox"
        id="privacy-policy"
        className="w-5 h-5 bg-white rounded-md"
      />

      <label
        htmlFor="privacy-policy"
        className="flex-1 shrink self-stretch my-auto text-base leading-6 text-gray-500 basis-0 max-md:max-w-full"
      >
        {label}
      </label>
    </div>
  );
}

export default CheckboxField;
